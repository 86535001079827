@import url(./vars.css);

body {
  background: var(--background-color);
  background-image: url("./assets/logo_python.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 50vh;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

h1 {
  font-size: 2rem;
  color: var(--color);
  font-weight: bold;
}