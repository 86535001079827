:root {
    --background-color: rgba(52, 56, 61, 1);
    --color: rgba(126, 141, 105, 1);
    --input: rgba(28, 30, 34, 0.6);
    --input-hover: rgba(28, 30, 34, 0.8);
    --input-focus-border: rgba(0, 255, 0, 0.4);
    --button: rgba(28, 30, 34, 1);
    --button-hover: rgba(28, 30, 34, 0.8);
    --button-active: rgba(28, 30, 34, 0.6);
    --placeholder: rgba(52, 56, 61, 1);
    --bar: rgba(51, 55, 60, 1);
    --baz: rgba(41, 36, 34, 1);
}