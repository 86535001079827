.btn {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s, opacity 0.3s;
}

.btn img {
    width: 60px;
    height: 60px;
    object-fit: contain;
}

.btn:hover {
    transform: scale(1.3);
    opacity: 0.8;
}
